import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import Fallback from 'components/Fallback';
import '@viva-finance/viva-components/dist/tailwind.css';
import '@viva-finance/viva-components/dist/public/index.css';

const App = lazy(() => import('App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Fallback />}>
    <App />
  </Suspense>
);
