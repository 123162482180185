import './styles.css';

// NOTE: Fallback should have minimal code. It is loaded first each time the app loads.
function Fallback() {
  return (
    <div className="absolute h-screen w-screen bg-white-100 z-10">
      <div className="absolute top-1/3 flex justify-center w-screen">
        <div className="flex justify-center items-center">
          <span className="loader" />
        </div>
      </div>
    </div>
  );
}

export default Fallback;
